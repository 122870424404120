
$('form').submit(function(event) {
  event.preventDefault();
  var string = $('textarea.what').val() + "\n" + $('textarea.why').val() + "\n" + $('input.email').val()

  $.ajax({
    url:          'https://hooks.slack.com/services/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',
    type:         "POST",
    data:         { payload: JSON.stringify({text: string}) }
    }
  ).done(function() {
    $('form').replaceWith("<p>Message received! Thanks for getting in touch, I'll get back yo ou as soon as I can.</p>");
  })
})
